import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#207CEE',
        secondary: '#EEEEEE',
        accent: '#FF6F00',
        error: '#EF5C54',
        info: '#83D6DE',
        success: '#499642',
        warning: '#FFC107',
        print: '#1D628B',
        more: '#721544'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },

  //theme: { dark: false },
});
