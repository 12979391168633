<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <!-- Listado Guias -->
        <v-data-table :loading="loading" :headers="cabeceraListarGuias" :items="guiasRemision" :items-per-page="15"
          item-key="idmovimiento" show-expand class="elevation-3"
          v-if="verNuevaGuiaRemision == 0 && verNuevaGuiaRemisionMasivo == 0" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">GUÍAS DE REMISIÓN</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Formulario busqueda -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="ReenvioMasivoSunat" color="primary" class="mr-1" fab x-small v-bind="attrs" v-on="on"
                    :loading="desabMasivo" :disabled="desabMasivo" outlined><v-icon>schedule_send</v-icon></v-btn>
                </template>
                <span>Envio masivo SUNAT</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="consultaTickectMasivoSunat" color="primary" class="mr-1" fab x-small v-bind="attrs"
                    v-on="on" :loading="desabMasivo" :disabled="desabMasivo"
                    outlined><v-icon>playlist_add_check</v-icon></v-btn>
                </template>
                <span>Consulta masiva SUNAT</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="impresionPdfMasivo" color="primary" class="mr-1" fab x-small v-bind="attrs" v-on="on"
                    :loading="desabMasivo" :disabled="desabMasivo" outlined><v-icon>dynamic_feed</v-icon></v-btn>
                </template>
                <span>Impresión masiva PDF</span>
              </v-tooltip>

              <template v-if="gnro_entrega != '' & gitem != '' & gruta != ''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="hojaCargaRuta" color="primary" class="mr-1" fab x-small v-bind="attrs" v-on="on"
                      :loading="desabMasivo" :disabled="desabMasivo" outlined><v-icon>local_shipping</v-icon></v-btn>
                  </template>
                  <span>Hoja de Carga</span>
                </v-tooltip>
              </template>
              <v-spacer />
              <v-menu v-model="menuBuscar" :close-on-content-click="!menuBuscar" transition="scale-transition" offset-y
                max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model.trim="buscar" label="Búsqueda" @keyup="listarGuiaRemision" v-on="on"
                    append-icon="filter_list" @click:append="menuBuscar = true" class="mr-1" :disabled="desabMasivo"
                    single-line hide-details></v-text-field>
                </template>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3" sm="3">
                        <v-select dense :items="aanos" v-model="gano" label="Año" @change="listarGuiaRemision()" required
                          hide-details></v-select>
                      </v-col>
                      <v-col cols="12" md="2" sm="2">
                        <v-select dense :items="anro_entregas" v-model="gnro_entrega" label="Entrega"
                          @change="gnro_entrega > 0 ? (gitem = '', dgitem = false) : (gitem = '', dgitem = true), gnro_entrega > 0 ? (gruta = '', dgruta = true) : (gruta = '', dgruta = true), listarGuiaRemision()"
                          required hide-details></v-select>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-select dense :items="aitems" v-model="gitem"
                          @change="descripcionItem(gitem), gitem > 0 ? (selectRuta('G'), gruta = '') : (arutas = [{ text: '' }], gruta = '', dgruta = true), listarGuiaRemision()"
                          label="Item" :disabled="dgitem" required hide-details></v-select>
                      </v-col>
                      <v-col cols="10" md="2" sm="2">
                        <v-select dense :items="arutas" v-model="gruta" @change="listarGuiaRemision()" label="Ruta"
                          :disabled="dgruta" required hide-details></v-select>
                      </v-col>
                      <v-col cols="2" md="1" sm="1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-simple-checkbox v-model="ordenar" v-on="on"
                              @click="listarGuiaRemision()"></v-simple-checkbox>
                          </template>
                          <span>Ordenar x entrega</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>

              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listarGuiaRemision" class="mr-1" :disabled="desabMasivo"
                    style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates" no-title @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listarGuiaRemision" range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="nuevaGuiaRemisionMasivo(), selectRuta('E')" color="primary" class="mr-1"
                    :disabled="desabMasivo" fab x-small v-bind="attrs" v-on="on"
                    outlined><v-icon>playlist_add</v-icon></v-btn>
                </template>
                <span>Nueva Guía de Remisión Masiva</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="nuevaGuiaRemision" color="primary" :disabled="desabMasivo" fab x-small v-bind="attrs"
                    v-on="on" outlined><v-icon>add</v-icon></v-btn>
                </template>
                <span>Nueva Guía de Remisión</span>
              </v-tooltip>
            </v-toolbar>
            <div id="container" style="position: absolute; visibility: hidden;" class="qrcode" ref="qrCodeUrl"></div>
          </template>
          <!-- Opciones para la lista de guias -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <template v-if="item.msj_cdr != ''">
                <strong>SUNAT: {{ item.msj_cdr }}</strong>
                <br />
              </template>
              <strong>Registrado por:</strong>
              {{ item.usuario }}
              <template v-if="item.nombre_iiee != ''">
                <br />
                <strong>IIEE: </strong> {{ item.nivel_educativo + " - " + item.nombre_iiee }}
                <strong>Cod. Modular: </strong>{{ item.cod_modular }}
              </template>
              <br />
              <strong>Dirección llegada: </strong>
              {{ item.direccion_llegada + " " + item.nom_ubi_llegada }}
            </td>
          </template>
          <template v-slot:item.numero="{ item }">
            <template>
              <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
            </template>
          </template>
          <template v-slot:item.nombre_persona="{ item }">
            <span>{{ item.nombre_persona.substring(0, 30) }}</span>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.fecha_comprobante="{ item }">
            <span>{{ item.fecha_comprobante | formatearFecha }}</span>
          </template>

          <!-- Estado de los comprobantes -->
          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado == 'Aceptado'">
              <strong class="success--text">{{ item.estado }}</strong>
            </template>
            <template v-else-if="item.estado == 'Enviado'">
              <span class="primary--text">{{ item.estado }}</span>
            </template>
            <template v-else-if="item.estado == 'Por enviar'">
              <span class="brown--text">{{ item.estado }}</span>
            </template>
            <template v-else-if="item.estado == 'Por anular'">
              <strong class="orange--text">{{ item.estado }}</strong>
            </template>
            <template v-else-if="item.estado == 'Rechazado'">
              <strong class="error--text">{{ item.estado }}</strong>
            </template>
            <template v-else-if="item.estado == 'Anulado'">
              <strong class="error--text">{{ item.estado }}</strong>
            </template>
            <template v-else>
              <span>{{ item.estado }}</span>
            </template>
          </template>
          <!-- Fin Estado de los comprobantes -->
          <!-- Fin Opciones para la lista de guias -->

          <!-- Descarga de XML y CDR -->
          <template v-slot:item.descargas="{ item }">
            <template>
              <v-chip v-if="item.estado == 'Enviado' | item.estado == 'Aceptado' | item.estado == 'Anulado'" color="cyan"
                class="white--text" x-small @click="descargarXml(item)" outlined>XML</v-chip>
            </template>
            <template v-if="item.estado == 'Aceptado' | item.estado == 'Anulado'">
              <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)" outlined>CDR</v-chip>
            </template>
          </template>
          <!-- Fin Descarga de XML y CDR -->

          <!-- Opciones de impresion, anular y cpe-->
          <template v-slot:item.opciones="{ item }">
            <template v-if="item.estado == 'Enviado' && item.nro_ticket != '' || item.estado == 'En proceso'">
              <v-btn x-small color="primary" class="white--text" @click="consultarTicket(item)" :disabled="desabMasivo"
                outlined>Consultar</v-btn>
            </template>
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)" :disabled="desabMasivo">print</v-icon>
            </v-btn>
            <template v-if="item.estado == 'Aceptado'">
              <template>
                <v-btn small icon>
                  <v-icon color="error" @click="anularMostrar(item)" :disabled="desabMasivo">delete</v-icon>
                </v-btn>
              </template>
            </template>
            <!-- Menu para mas opciones de FE -->
            <template v-if="item.estado == 'Creado' && desabMasivo == false">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small icon>
                    <v-icon v-on="on" color="more">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <!-- Reenviar Sunat -->
                  <v-list-item link v-if="item.estado == 'Creado'">
                    <v-list-item-content>
                      <v-list-item-title class="more--text" @click="reenviarSunat(item)">Enviar a la SUNAT
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- Fin Reenviar Sunat -->
                </v-list>
              </v-menu>
            </template>
            <!-- Fin Menu para mas opciones de FE -->
          </template>
          <!-- Fin Opciones de impresion, anular y cpe-->
        </v-data-table>
        <!--Fin Listado Guias -->

        <!-- Ventana guia de remisión -->
        <v-card v-if="verNuevaGuiaRemision == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nueva Guía de Remisión</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="guardarGuiaRemision" :loading="desabilitar" :disabled="desabilitar || !isValid">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon @click="ocultarGuiaRemision">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text grid-list-sm v-if="verNuevaGuiaRemision">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="12" md="2" sm="3">
                  <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field dense v-model="fecha_comprobante_formateada" label="Fecha traslado" readonly v-on="on"
                        prepend-inner-icon="event" hide-details></v-text-field>
                    </template>
                    <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                      @change="fechaseleccionada(fecha_comprobante)"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="8" sm="8">
                  <v-autocomplete dense @keyup="selectDestinatario()" @change="changeDestinatario"
                    :search-input.sync="buscarDestinatario" v-model="doc_persona" :items="destinatarios"
                    label="Destinatario" :rules="[v => (!!v && v > 0) || 'El destinatario es requerido']" clearable
                    hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-select dense v-model="idmodalidad_transporte" :items="modalidad_transportes" label="Transporte"
                    :rules="[v => !!v || 'El modo de traslado es requerido']"
                    @change="idmodalidad_transporte == '01' ? (doc_conductor = '0', placa = '0') : doc_transportista = '0'"
                    hide-details>
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-select dense v-model="idmotivo_traslado" :items="motivo_traslados" label="Motivo de Traslado"
                    :rules="[v => !!v || 'Motivo requerido']" @change="descripcionMotivo(idmotivo_traslado)"
                    hide-details></v-select>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                  <v-text-field dense v-model.trim="motivo" label="Descripción de motivo de traslado"
                    :rules="[v => (v.length >= 5 && v.length <= 100) || 'El motivo puede tener de 5 a 100 caracteres', Rules.unespacio]"
                    maxlength="100" v-uppercase hide-details></v-text-field>
                </v-col>
                <v-col cols="4" md="2" sm="2">
                  <v-text-field dense v-model="peso_total" label="Peso total (KGM)"
                    :rules="[v => (!!v && v > 0) || 'Peso total es requerido']" maxlength="16" hide-details
                    @keypress="common.numDec($event, peso_total, 3)"></v-text-field>
                </v-col>
                <v-col cols="8" md="4" sm="4">
                  <v-text-field dense v-model.trim="observacion" label="Observación"
                    :rules="[v => (v.length <= 200) || 'La observacion puede tener hasta 200 caracteres', Rules.unespacio]"
                    maxlength="200" v-uppercase hide-details></v-text-field>
                </v-col>

                <v-col cols="12" md="5" sm="4">
                  <v-autocomplete dense @keyup="selectUbigeoPartida()" :search-input.sync="buscarUbigeoPartida"
                    v-model="idubigeo_partida" :items="ubigeos_partida" label="Ubigeo partida"
                    :rules="[v => !!v || 'Ubigeo requerido']" clearable hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="7" sm="8">
                  <v-text-field dense v-model.trim="direccion_partida" label="Dirección partida"
                    :rules="[v => (v.length >= 5 && v.length <= 500) || 'La dirección puede tener de 5 a 500 caracteres', Rules.unespacio]"
                    maxlength="500" v-uppercase hide-details></v-text-field>
                </v-col>
                <v-col cols="12" md="5" sm="4">
                  <v-autocomplete dense @keyup="selectUbigeoLlegada()" :search-input.sync="buscarUbigeoLlegada"
                    v-model="idubigeo_llegada" :items="ubigeos_llegada" label="Ubigeo llegada"
                    :rules="[v => !!v || 'Ubigeo requerido']" clearable hide-details></v-autocomplete>
                </v-col>
                <v-col cols="12" md="7" sm="8">
                  <v-text-field dense v-model.trim="direccion_llegada" label="Dirección llegada"
                    :rules="[v => (v.length >= 5 && v.length <= 500) || 'La dirección puede tener de 5 a 500 caracteres', Rules.unespacio]"
                    maxlength="500" v-uppercase hide-details></v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" v-if="idmodalidad_transporte == '01'">
                  <v-autocomplete dense @keyup="selectTransportista()" :search-input.sync="buscarTransportista"
                    v-model="doc_transportista" :items="transportistas" label="Transportista"
                    :rules="[v => (!!v && v > 0) || 'El transportista es requerido']" clearable
                    hide-details></v-autocomplete>
                </v-col>
                <template v-if="idmodalidad_transporte == '02'">
                  <v-col cols="6" md="6" sm="6">
                    <v-autocomplete dense @keyup="selectConductor()" :search-input.sync="buscarConductor"
                      v-model="doc_conductor" :items="conductores" label="Conductor"
                      :rules="[v => (!!v && v > 0) || 'El conductor es requerido']" clearable
                      hide-details></v-autocomplete>
                  </v-col>
                  <v-col cols="10" md="4" sm="4">
                    <v-autocomplete dense @keyup="selectVehiculo()" :search-input.sync="buscarVehiculo" v-model="placa"
                      :items="vehiculos" label="Vehículo"
                      :rules="[v => (!!v && v.length > 3) || 'El vehículo es requerido']" clearable
                      hide-details></v-autocomplete>
                  </v-col>
                </template>

                <v-col cols="1" md="1" sm="1" v-if="!decarga">
                  <div class="text-center">
                    <v-btn @click="agregarDetalle" x-small fab color="primary" outlined>
                      <v-icon>playlist_add</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-data-table :headers="decarga == true ? cabeceraDetallesGRCarga : cabeceraDetallesGR"
                    :items="detalles" disable-pagination hide-default-footer dense class="elevation-1">

                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" class="mr-2" @click="eliminarItemDetallePedidoGR(detalles, item)">
                        delete_forever</v-icon>
                    </template>
                    <template v-slot:item.cod_producto="{ item }">
                      <v-text-field dense type="text" v-model="item.cod_producto" :rules="[v => !!v || 'Requerido']"
                        maxlength="10" :disabled="decarga" v-uppercase hide-details></v-text-field>
                    </template>
                    <template v-slot:item.descrip_producto="{ item }">
                      <v-text-field dense type="text" v-model="item.descrip_producto"
                        :rules="[v => (v.length >= 5 && v.length <= 500) || 'La descripción puede tener de 5 a 500 caracteres', Rules.unespacio]"
                        maxlength="500" v-uppercase hide-details></v-text-field>
                    </template>
                    <template v-slot:item.idunidad_medida="{ item }">
                      <v-autocomplete dense :items="unidad_medidas" v-model="item.idunidad_medida"
                        :rules="[v => !!v || 'La unidad de medida es requerido']" :disabled="decarga" required
                        hide-details></v-autocomplete>
                    </template>
                    <template v-slot:item.cantidad="{ item }">
                      <v-text-field dense type="text" v-model="item.cantidad"
                        :rules="[v => /^-?\d*(\.\d+)?$/.test(v) || 'Debe ser un número']" maxlength="12"
                        :disabled="decarga" hide-details class="center-input"
                        @keypress="common.isNum($event)"></v-text-field>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Fin Ventana guia de remisión -->

        <!-- Ventana guia de remisión masivo -->
        <v-card v-if="verNuevaGuiaRemisionMasivo == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nueva Guía de Remisión Masiva - Lista de Entregas</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="guardarGuiaRemision" :loading="desabilitar" :disabled="desabilitar || !isValid">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon @click="ocultarGuiaRemisionMasivo">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text grid-list-sm v-if="verNuevaGuiaRemisionMasivo">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="5" md="2" sm="3">
                  <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field dense v-model="fecha_comprobante_formateada" label="Fecha traslado" readonly v-on="on"
                        prepend-inner-icon="event" hide-details></v-text-field>
                    </template>
                    <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                      @change="fechaseleccionada(fecha_comprobante)"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" md="2" sm="2">
                  <v-select dense :items="aanos" v-model="eano" label="Año" @change="listarEntregaCarga()"
                    :rules="[v => !!v || 'Año es requerido']" required hide-details></v-select>
                </v-col>
                <v-col cols="3" md="2" sm="2">
                  <v-select dense :items="anro_entregas.slice(1)" v-model="enro_entrega" label="Entrega"
                    @change="selectRuta('E'), eruta = '', listarEntregaCarga()" required hide-details></v-select>
                </v-col>
                <v-col cols="6" md="3" sm="3">
                  <v-select dense :items="aitems.slice(1)" v-model="eitem"
                    @change="selectRuta('E'), eruta = '', listarEntregaCarga()" label="Item" required
                    hide-details></v-select>
                </v-col>
                <v-col cols="4" md="2" sm="2">
                  <v-select dense :items="arutas" v-model="eruta" label="Ruta" @change="listarEntregaCarga()" required
                    hide-details></v-select>
                </v-col>
                <v-col cols="12">
                  <v-data-table :loading="eloading" :headers="cabeceraDetallesGrMasivo" :items="entregas"
                    :items-per-page="-1" dense class="elevation-1">
                    <template v-slot:item.nro="{ item }">
                      <td>{{ entregas.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.institucion="{ item }">
                      {{ item.nivel_educativo }} -
                      <strong> {{ item.nombre_iiee }}</strong>
                    </template>
                    <template v-slot:item.transporte="{ item }">
                      <template v-if="item.idmodalidad_transporte = '02'">
                        {{ item.doc_conductor + " - " + item.nombres_conductor + " " + item.apellidos_conductor + " - " +
                          item.licencia_conductor }} -> <strong>{{ item.placa + " " + item.tipo_vehiculo }}</strong>
                      </template>
                      <template v-else>
                        {{ item.doc_transportista + " - " + item.nombre_emp_transp }}
                      </template>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- Fin Ventana guia de remisión masivo -->

        <!-- Modal email Guia -->
        <v-dialog v-model="adModalEmailCliente" max-width="500" persistent>
          <v-card>
            <v-card-title class="text-h6">
              {{ adComprobante }}
              <v-spacer />
              <v-btn icon @click="emailCerrar" small>
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="justify-center">{{ nombre_persona }}</v-card-subtitle>
            <v-card-text>
              <v-form v-model="isValid">
                <v-text-field dense class="text-xs-center" v-model.trim="email" label="Email"
                  :rules="[v => /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]" maxlength="60"
                  v-lowercase hide-details></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="py-0 pb-4 justify-center">
              <v-btn color="success" class="white--text" :loading="desabilitar" :disabled="desabilitar || !isValid"
                @click="enviarEmail()" small>
                <v-icon left>send</v-icon>Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Modal email Guia -->

        <!-- Modal anular guia de remision-->
        <v-dialog v-model="adModal" max-width="330">
          <v-card>
            <v-card-title class="justify-center">¿Anular Guía de Remisión?
            </v-card-title>
            <v-card-text>
              Estás a punto de Anular la Guía de Remisión: {{ adComprobante }}, tambien se debería realizar la anulación
              en SUNAT con su clave SOL.
            </v-card-text>
            <v-card-actions class="py-0 pb-4 justify-center">
              <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
              <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Modal anular guia de remision-->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="800px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Guía de Remisión Remitente</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="emailMostrar()" v-if="idtipo_comprobante != '00'" :disabled="disableBMail">
                <v-icon>attach_email</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobanteImp()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import pdf from "vue-pdf";
import QRCode from "qrcodejs2";
import { PDFDocument } from "pdf-lib";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      guiasRemision: [],
      entregas: [],

      cabeceraListarGuias: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "E", value: "nro_entrega", sortable: false },
        { text: "ITEM", value: "item_nombre", sortable: false },
        { text: "R", value: "ruta", sortable: false },
        { text: "NÚMERO", value: "numero", sortable: false, width: 135 },
        { text: "DESTINATARIO", value: "nombre_persona", sortable: false },
        { text: "F/EMISIÓN", value: "fecha", sortable: false },
        { text: "F/TRASLADO", value: "fecha_comprobante", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 145 },
      ],

      cabeceraDetallesGR: [
        { text: "", value: "borrar", sortable: false, width: 10 },
        { text: "#", value: "nro", sortable: false, width: 10 },
        { text: "CÓDIGO", value: "cod_producto", sortable: false, width: 100 },
        { text: "DESCRIPCIÓN", value: "descrip_producto", sortable: false },
        { text: "UNIDAD", value: "idunidad_medida", sortable: false, width: 130 },
        { text: "CANT.", value: "cantidad", sortable: false, width: 100 },
      ],

      cabeceraDetallesGRCarga: [
        //{ text: "", value: "borrar", sortable: false, width: 10 },
        { text: "#", value: "nro", sortable: false, width: 10 },
        { text: "CÓDIGO", value: "cod_producto", sortable: false, width: 120 },
        { text: "DESCRIPCIÓN", value: "descripcion_detallada", sortable: false },
        { text: "UNIDAD", value: "idunidad_medida", sortable: false, width: 130 },
        { text: "CANT.", value: "cantidad", sortable: false, width: 100 },
      ],
      cabeceraDetallesGrMasivo: [
        { text: "", value: "nro", sortable: false },
        { text: "R", value: "ruta", sortable: false, divider: true },
        { text: "C/MOD.", value: "cod_modular", sortable: false },
        { text: "INSTITUCIONES", value: "institucion", sortable: false, divider: true },
        { text: "TRANSPORTE", value: "transporte", sortable: false },
      ],

      detalles: [],
      destinatarios: [],
      transportistas: [],
      conductores: [],
      vehiculos: [],
      decarga: false,
      hojaCarga: [],

      loading: false,

      arrayBufferPdf: [],

      buscar: "",
      menuBuscar: false,

      buscarDestinatario: "",
      buscarTransportista: "",
      buscarConductor: "",
      buscarVehiculo: "",

      buscarUbigeoPartida: "",
      buscarUbigeoLlegada: "",

      idmovimiento: 0,
      //idtipo_movimiento: "",
      idtipo_comprobante: "",
      idmotivo_traslado: "",
      serie_comprobante: "",
      num_comprobante: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),
      fecha: "",

      motivo: "",
      observacion: "",

      // Entrega
      ano: "",
      nro_entrega: "",
      item: "",
      ruta: "",
      doc_persona: "0",
      nombre_iiee: "",
      nivel_educativo: "",
      cod_modular: "",
      idubigeo_partida: "",
      ubigeos_partida: [],
      direccion_partida: "",
      idubigeo_llegada: "",
      ubigeos_llegada: [],
      direccion_llegada: "",
      centro_poblado: "",

      idmodalidad_transporte: "01",
      doc_transportista: "0",
      doc_conductor: "0",
      placa: "0",
      idunidad_medida: "",
      peso_total: "",

      // Impresion
      nombre_persona: "",
      nombre_emp_transp: "",
      nombres_conductor: "",
      apellidos_conductor: "",
      licencia_conductor: "",
      tipo_vehiculo: "",

      motivo_traslados: [],
      unidad_medidas: [], // select en detalles

      modalidad_transportes: [
        { text: "Público", value: "01" },
        { text: "Privado", value: "02" },
      ],

      dgitem: true,
      dgruta: true,

      // Filtro Guia
      ordenar: false,
      gano: "2023",
      gnro_entrega: "",
      gitem: "",
      gruta: "",
      // Filtros Masivo
      eano: "2023",
      enro_entrega: "1",
      eitem: "1",
      eruta: "",
      aanos: [
        { text: '2023' },
        { text: '2024' },
      ],
      arutas: [{ text: "" }],
      aitems: [],
      anro_entregas: [
        { text: "" },
        { text: "1" },
        { text: "2" },
        { text: "3" },
        { text: "4" },
        { text: "5" },
        { text: "6" },
        { text: "7" },
        { text: "8" },
      ],
      // Fin Filtros Masivo

      email: "", // Para enviar correo con xml y cdr
      disableBMail: true,

      comprobanteModal: 0,
      verNuevaGuiaRemision: 0,
      verNuevaGuiaRemisionMasivo: 0,

      desabilitar: false, // Desabilitamos boton guardar
      desabMasivo: false,
      isValid: true, // validamos que todos lo campos sean correctos
      isValidP: true,

      adModalEmailCliente: 0,

      adModal: 0,
      adId: "",
      adComprobante: "",

      stringPdf: "",
      blobPdf: "",

      fecha_actual: moment().format("YYYY-MM-DD"),
      fecha_comprobante_formateada: "",
      menuFecha: false,

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobanteImp();
    },

  },

  created() {
    this.idubigeo_partida = this.usuario.ubigeo;
    this.direccion_partida = this.usuario.direccion
    this.fechaseleccionada(this.fecha_comprobante);
    this.select();
    this.listarGuiaRemision();
  },

  methods: {
    ...mapActions(["snackBar", "cargarNotifCpes"]),

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    changeDestinatario(valor) {
      if (valor != null) {
        const selectId = this.destinatarios.find(
          (elemento) => elemento.value === valor
        );
        this.idubigeo_llegada = selectId.ubigeo;
        this.selectUbigeoLlegada(this.idubigeo_llegada);
        this.direccion_llegada = selectId.direccion_llegada;
        //console.log(this.idtipo_documento) // mostrar que tipo documento de cliente 1 ó 6
      }
    },

    //#region SElECT
    // De carga
    selectDestinatarioCarga(id) {
      let me = this;
      var destinatariosArray = []; // Limpiamos para cargar nuevamente

      axios.get("api/Personas/Select/" + id)
        .then(function (response) {
          destinatariosArray = response.data;
          destinatariosArray.map(function (x) {
            me.destinatarios.push({
              text: x.doc_persona + " " + x.nombre,
              value: x.doc_persona,
              ubigeo: x.idubigeo,
              direccion_llegada: x.direccion,
            });
          });
        })
        .then((data) => {
          me.changeDestinatarioCarga(id);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    listarDetalleCargaItem(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Cargas/ListarCargaDetalle", {
        params: {
          ano: me.ano,
          nro_entrega: me.nro_entrega,
          item: me.item,
          cod_modular: me.cod_modular
        },
      })
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            let nro = me.detalles.length + 1;

            me.detalles.push({
              cod_producto: x.cod_producto,
              descrip_producto: x.descrip_producto,
              marca: x.marca,
              presentacion: x.presentacion,
              medida: x.medida,
              lote: x.lote,
              idunidad_medida: x.idunidad_medida,
              cantidad: x.cantidad,
              empaque: x.empaque,
              nro: nro,

              descripcion_detallada: x.descrip_producto + " " + x.marca + " " + x.presentacion.toFixed(3) + " " + x.medida + " Lote: " + x.lote
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    // Fin carga

    selectDestinatario(id) {
      let me = this;
      var destinatariosArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios.get("api/Personas/Select/" + id)
          .then(function (response) {
            destinatariosArray = response.data;
            destinatariosArray.map(function (x) {
              me.destinatarios.push({
                text: x.doc_persona + " " + x.nombre,
                value: x.doc_persona,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Personas/SelectFiltro", {
          params: {
            buscar: me.buscarDestinatario == "" ? null : me.buscarDestinatario,
          },
        })
          .then(function (response) {
            destinatariosArray = response.data;
            destinatariosArray.map(function (x) {
              me.destinatarios.push({
                text: x.doc_persona + " " + x.nombre,
                value: x.doc_persona,
                ubigeo: x.idubigeo,
                direccion_llegada: x.direccion,
              });
            });
          })
          .then((data) => {
            me.changeDestinatario(id); // En observacion
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectTransportista(id) {
      let me = this;

      var transportistaArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios.get("api/Transportistas/Select/" + id)
          .then(function (response) {
            transportistaArray = response.data;
            transportistaArray.map(function (x) {
              me.transportistas.push({
                text: x.doc_transportista + " " + x.nombre,
                value: x.doc_transportista,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Transportistas/SelectFiltro", {
          params: {
            buscar: me.buscarTransportista == "" ? null : me.buscarTransportista,
          },
        })
          .then(function (response) {
            transportistaArray = response.data;
            transportistaArray.map(function (x) {
              me.transportistas.push({
                text: x.doc_transportista + " " + x.nombre,
                value: x.doc_transportista,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectConductor(id) {
      let me = this;

      var conductoresArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios.get("api/Conductores/Select/" + id)
          .then(function (response) {
            conductoresArray = response.data;
            conductoresArray.map(function (x) {
              me.conductores.push({
                text: x.doc_conductor + " " + x.nombres + " " + x.apellidos,
                value: x.doc_conductor,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Conductores/SelectFiltro", {
          params: {
            buscar: me.buscarConductor == "" ? null : me.buscarConductor,
          },
        })
          .then(function (response) {
            conductoresArray = response.data;
            conductoresArray.map(function (x) {
              me.conductores.push({
                text: x.doc_conductor + " " + x.nombres + " " + x.apellidos,
                value: x.doc_conductor,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectVehiculo(id) {
      let me = this;

      var vehiculosArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios.get("api/Vehiculos/Select/" + id)
          .then(function (response) {
            vehiculosArray = response.data;
            vehiculosArray.map(function (x) {
              me.vehiculos.push({
                text: x.placa + " " + x.tipo,
                value: x.placa,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Vehiculos/SelectFiltro", {
          params: {
            buscar: me.buscarVehiculo == "" ? null : me.buscarVehiculo,
          },
        })
          .then(function (response) {
            vehiculosArray = response.data;
            vehiculosArray.map(function (x) {
              me.vehiculos.push({
                text: x.placa + " " + x.tipo,
                value: x.placa,
              });
            });
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeoPartida(id) {
      let me = this;
      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_partida.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscarUbigeoPartida == ""
                ? "-"
                : me.buscarUbigeoPartida == null
                  ? "-"
                  : me.buscarUbigeoPartida,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_partida.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeoLlegada(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_llegada.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscarUbigeoLlegada == ""
                ? "-"
                : me.buscarUbigeoLlegada == null
                  ? "-"
                  : me.buscarUbigeoLlegada,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos_llegada.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectRuta(val) {
      let me = this;
      me.arutas = [{ text: "" }];

      var rutasArray = [];

      let url = "";
      if (val == "G") {
        url = "api/Entregas/SelectRuta/" + me.gano + "/" + me.gnro_entrega + "/" + me.gitem;
      } else {
        url = "api/Entregas/SelectRuta/" + me.eano + "/" + me.enro_entrega + "/" + me.eitem;
      }

      axios.get(url)
        .then(function (response) {
          rutasArray = response.data;
          rutasArray.map(function (x) {
            me.arutas.push({
              text: x.rutas,
            });
            me.dgruta = false
          });
        })
        .catch(function (error) { });
    },

    descripcionItem(valor) {
      if (valor != null) {
        const selectId = this.aitems.find(
          (elemento) => elemento.value === valor
        );
        this.nomitem = selectId.text;
      }
    },

    select() {
      let me = this;

      var motivo_trasladosArray = [];
      axios.get("api/Motivo_Traslados/Select")
        .then(function (response) {
          motivo_trasladosArray = response.data;
          motivo_trasladosArray.map(function (x) {
            me.motivo_traslados.push({
              text: x.descripcion,
              value: x.idmotivo_traslado,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var unidad_medidasArray = [];
      axios.get("api/Unidad_Medidas/Select")
        .then(function (response) {
          unidad_medidasArray = response.data;
          unidad_medidasArray.map(function (x) {
            me.unidad_medidas.push({
              text: x.nombre,
              value: x.idunidad_medida,
            });
          });
        })
        .catch(function (error) { });

      var item_entradasArray = [];
      axios.get("api/Item_Entregas/Select")
        .then(function (response) {
          item_entradasArray = response.data;
          item_entradasArray.map(function (x) {
            me.aitems.push({
              text: x.nombre,
              value: x.item,
            });
          });
        })
        .catch(function (error) { });
    },
    //#endregion SELECT

    //#region GUIA REMISION
    listarGuiaRemision() {
      let me = this;
      me.loading = true;
      me.decarga = me.$route.params.carga; // true

      if (me.decarga === true) {
        me.ano = me.$route.params.cargaano;
        me.nro_entrega = me.$route.params.carganro_entrega;
        me.item = me.$route.params.cargaitem;
        me.ruta = me.$route.params.cargaruta;
        me.doc_persona = me.$route.params.cargadocpersona;
        me.nombre_iiee = me.$route.params.carganombreiiee;
        me.direccion_llegada = me.$route.params.cargadireccion_llegada;
        me.nivel_educativo = me.$route.params.carganiveleducativo;
        me.cod_modular = me.$route.params.cargacodmodular;
        me.idubigeo_llegada = me.$route.params.cargaidubigeo;
        me.centro_poblado = me.$route.params.cargacentropoblado;
        me.peso_total = me.$route.params.cargapesototal;

        me.observacion = me.cod_modular + " IIEE: " + me.nivel_educativo + " - " + me.nombre_iiee;
        me.idmotivo_traslado = "01";
        me.motivo = "VENTA";

        // Cargamos detalle de carga 
        me.listarDetalleCargaItem();

        me.selectDestinatarioCarga(me.doc_persona);
        me.selectUbigeoPartida(me.idubigeo_partida);
        me.selectUbigeoLlegada(me.idubigeo_llegada);

        me.verNuevaGuiaRemision = 1;
        me.$route.params.carga = false;
      } else {
        axios.get("api/Movimientos/ListarGuiaRemisionFiltro", {
          params: {
            buscar: me.buscar,
            ano: me.gano,
            nro_entrega: me.gnro_entrega,
            item: me.gitem,
            ruta: me.gruta,
            ordenar: me.ordenar, // true = identrega; false = idmovimiento
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.guiasRemision = response.data;
            me.loading = false;
            me.cargarNotifCpes();// Actualizamos Notificacion Cpes
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    descripcionMotivo(valor) {
      if (this.motivo_traslados.length > 0) {
        const selectId = this.motivo_traslados.find(
          (elemento) => elemento.value === valor
        );
        this.motivo = this.removeAccents(selectId.text.toUpperCase());
      }
    },

    reenviarSunat(item) {
      let me = this;

      me.desabMasivo = true;

      axios.put("api/Movimientos/ReenviarGuiaRemisionSunat/" + item.idmovimiento)
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
          me.listarGuiaRemision();
          me.limpiar();
          me.desabMasivo = false;
        })
        .catch(function (error) {
          me.limpiar();
          me.snackBar({ cl: "error", msg: error.response.data });
          me.desabMasivo = false;
        });
    },

    consultarTicket(item) {
      let me = this;

      me.desabMasivo = true;

      axios.put("api/Movimientos/ConsultarTicketGre/" + item.idmovimiento)
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
          me.listarGuiaRemision();
          me.limpiar();
          me.desabMasivo = false;
        })
        .catch(function (error) {
          me.listarGuiaRemision();
          me.limpiar();
          me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
          me.desabMasivo = false;
        });
    },

    async ReenvioMasivoSunat() {
      let me = this;
      for (var i = me.guiasRemision.length - 1; i >= 0; i--) {
        me.desabMasivo = true;
        if (me.guiasRemision[i].estado == 'Creado') {
          var id = me.guiasRemision[i].idmovimiento;
          const response = await axios.put("api/Movimientos/ReenviarGuiaRemisionSunat/" + id)
            .then(function (response) {
              me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
              me.listarGuiaRemision();
              me.limpiar();
            })
            .catch(function (error) {
              me.listarGuiaRemision();
              me.limpiar();
              me.snackBar({ cl: "error", msg: error.response.data });
            });
        }
      }
      me.desabMasivo = false;
      me.snackBar({ cl: "info", msg: "No existen guías de remisión para ENVIAR" });
    },

    async consultaTickectMasivoSunat() {
      let me = this;

      for (var i = me.guiasRemision.length - 1; i >= 0; i--) {
        me.desabMasivo = true;
        if (me.guiasRemision[i].estado == 'Enviado') {
          var id = me.guiasRemision[i].idmovimiento;
          const response = await axios.put("api/Movimientos/ConsultarTicketGre/" + id)
            .then(function (response) {
              me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
              me.listarGuiaRemision();
              me.limpiar();
            })
            .catch(function (error) {
              me.listarGuiaRemision();
              me.limpiar();
              me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
            });
        }
      }
      me.desabMasivo = false;
      me.snackBar({ cl: "info", msg: "No existen guías de remisión para CONSULTAR" });
    },

    guardarGuiaRemision() {
      let me = this;

      if (Date.parse(me.fecha_actual) > Date.parse(me.fecha_comprobante)) {
        return me.snackBar({ cl: "warning", msg: "La fecha no puede ser menor a la fecha actual" });
      }
      me.desabilitar = true; // Desabilitamos boton guardar

      if (me.verNuevaGuiaRemision == 1) {
        // Validamos si detalles esta vacio
        if (me.detalles.length <= 0) {
          return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
        }
        axios.post("api/Movimientos/CrearGuiaRemision", {
          idusuario: parseInt(me.usuario.idusuario),
          idmotivo_traslado: me.idmotivo_traslado,
          fecha_comprobante: me.fecha_comprobante,
          motivo: me.motivo,
          observacion: me.observacion,

          // Entrega
          ano: me.ano, //
          nro_entrega: me.nro_entrega, //
          item: me.item, //
          ruta: me.ruta, //
          doc_persona: me.doc_persona,
          nombre_iiee: me.nombre_iiee,
          nivel_educativo: me.nivel_educativo,
          cod_modular: me.cod_modular,
          nombre_iiee: me.nombre_iiee, //
          nivel_educativo: me.nivel_educativo, //
          cod_modular: me.cod_modular, //
          idubigeo_partida: me.idubigeo_partida,
          direccion_partida: me.direccion_partida,
          idubigeo_llegada: me.idubigeo_llegada,
          direccion_llegada: me.direccion_llegada,
          centro_poblado: me.centro_poblado, //
          idmodalidad_transporte: me.idmodalidad_transporte,
          doc_transportista: me.doc_transportista,
          doc_conductor: me.doc_conductor,
          placa: me.placa,
          peso_total: me.peso_total,
          // Detalles Entrega
          detalle_entregas: me.detalles,
        })
          .then(function (response) {
            me.ocultarGuiaRemision();
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
            me.listarGuiaRemision();
            me.limpiar();
          })
          .catch(function (error) {
            //me.ocultarGuiaRemision();
            me.snackBar({ cl: "error", msg: error.response.data });
            //me.listarGuiaRemision();
          });
      } else if (me.verNuevaGuiaRemisionMasivo == 1) {
        // Validamos si entregas esta vacio
        if (me.entregas.length <= 0) {
          return me.snackBar({ cl: "warning", msg: "Ingrese al menos una institución al detalle." });
        }
        axios.post("api/Movimientos/CrearGuiaRemisionMasivo", {
          idusuario: parseInt(me.usuario.idusuario),
          fecha_comprobante: me.fecha_comprobante,
          // Solo detalle con identrega es valido
          detalle_entrega_masivos: me.entregas,
        })
          .then(function (response) {
            me.ocultarGuiaRemisionMasivo();
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
            me.listarGuiaRemision();
            me.limpiar();
          })
          .catch(function (error) {
            //me.ocultarGuiaRemisionMasivo();
            me.snackBar({ cl: "error", msg: error.response.data });
            //me.listarGuiaRemision();
          });
      } else {
        me.ocultarGuiaRemision();
        me.ocultarGuiaRemisionMasivo();
        me.snackBar({ cl: "error", msg: "Hubo un error" });
        me.listarGuiaRemision();
      }

    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    nuevaGuiaRemision() {
      this.verNuevaGuiaRemision = 1;
      this.selectUbigeoPartida(this.idubigeo_partida);
    },

    ocultarGuiaRemision() {
      this.verNuevaGuiaRemision = 0;
      this.desabilitar = false; // Habilitamos boton guardar GR
      this.limpiar();
      this.listarGuiaRemision();
    },

    descargarXml(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarXml/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            me.usuario.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    descargarCdr(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarCdr/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "R-" +
            me.usuario.ruc +
            "-" +
            item.idtipo_comprobante +
            "-" +
            item.serie_comprobante +
            "-" +
            item.num_comprobante +
            ".xml"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    agregarDetalle() {
      let me = this;
      let nro = me.detalles.length + 1;
      me.detalles.push({
        cod_producto: "",
        descrip_producto: "",
        marca: "",
        presentacion: 0,
        medida: "",
        lote: "",
        idunidad_medida: "",
        cantidad: "",
        empaque: 0,
        nro: nro
      });
    },

    async listarDetalleEntregas(id) {
      let me = this;
      var detallesArray = [];

      const response = await axios.get("api/Entregas/ListarDetallesEntregas/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detalles.push({
              identrega: x.identrega,
              cod_producto: x.cod_producto,
              descrip_producto: x.descrip_producto,
              //presentacion: x.presentacion,
              //idunidad_medida: x.idunidad_medida,
              cantidad: x.cantidad,
              // Impresion
              nom_unidad_medida: x.nom_unidad_medida,
              descripcion_detallada: x.descripcion_detallada
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    eliminarItemDetallePedidoGR(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);

        // Actualizar los correlativos restantes
        for (var j = 0; j < arr.length; j++) {
          arr[j].nro = j + 1; // Actualizar el correlativo sumando 1 al índice
        }
      }
    },

    /* Anular */
    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adComprobante = item.serie_comprobante + "-" + item.num_comprobante;
    },

    anularCerrar() {
      this.limpiarAnular();
    },

    anular() {
      let me = this;

      axios.put("api/Movimientos/AnularGuiaRemision/" + me.adId)
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
          me.limpiarAnular();
          me.listarGuiaRemision();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.limpiarAnular();
          me.listarGuiaRemision();
        });
    },

    limpiarAnular() {
      this.adModal = 0;
      this.adId = "";
      this.adComprobante = "";
    },
    /* Fin Anular */

    //#endregion GUIA REMISION

    //#region ENTREGA
    listarEntregaCarga() {
      let me = this;
      me.eloading = true;

      axios.get("api/Entregas/ListarEntregasMasivo", {
        params: {
          ano: me.eano,
          ruta: me.eruta,
          nro_entrega: me.enro_entrega,
          item: me.eitem,
        },
      })
        .then(function (response) {
          me.entregas = response.data;
          me.eloading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    nuevaGuiaRemisionMasivo() {
      this.verNuevaGuiaRemisionMasivo = 1;
      this.listarEntregaCarga();
    },

    ocultarGuiaRemisionMasivo() {
      this.verNuevaGuiaRemisionMasivo = 0;
      this.desabilitar = false; // Habilitamos boton guardar GR
      this.limpiar();
      this.limpiarGuiaRemisionMasivo();
      this.listarGuiaRemision();
    },

    limpiarGuiaRemisionMasivo() {
      //this.eruta = "1"
      //this.eano = "2023";
      //this.eitem = "1";
      //this.enro_entrega = "1";
      this.limpiar();
    },
    //endregion ENTREGA

    //#region IMPRESION
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.crearPDF(item.idmovimiento, item.identrega);
      this.arrayBufferPdf = [];
      this.comprobanteModal = 1;
    },

    ocultarComprobanteImp() {
      this.comprobanteModal = 0;
      this.disableBMail = true;
      this.limpiar();
    },
    //#endregion IMPRESION

    //#region ENVIAR EMAIL
    enviarEmail() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton enviar

      axios.put("api/Movimientos/EnviarEmail", {
        idmovimiento: me.idmovimiento,
        nombre_persona: me.nombre_persona,
        email: me.email,
      })
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
          me.emailCerrar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    emailMostrar() {
      this.adModalEmailCliente = 1;
      this.adComprobante = this.serie_comprobante + "-" + this.num_comprobante;
    },

    emailCerrar() {
      this.adModalEmailCliente = 0;
      this.desabilitar = false;
    },
    //#endregion ENVIAR EMAIL


    limpiar() {
      this.idmovimiento = 0;

      this.idusuario = "";
      this.identrega = "";
      //this.idtipo_movimiento = selectId.idtipo_movimiento;
      this.idtipo_comprobante = "";
      this.idmotivo_traslado = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.fecha_comprobante = "";
      this.fecha = "";

      this.msj_cdr = "";
      this.link_cpe = "";
      this.nro_ticket = "";
      this.motivo = "";
      this.ref_comprobante = "";
      this.observacion = "";

      this.nom_comprobante = "";

      // Entregas
      this.ano = "";
      this.nro_entrega = "";
      this.item = "";
      this.ruta = "";
      this.doc_persona = "0";
      this.nombre_iiee = "";
      this.nivel_educativo = "";
      this.cod_modular = "";
      this.idubigeo_partida = this.usuario.ubigeo;
      this.direccion_partida = this.usuario.direccion;
      this.idubigeo_llegada = "";
      this.direccion_llegada = "";
      this.centro_poblado = "";
      this.idmodalidad_transporte = "01";
      this.doc_transportista = "0";
      this.doc_conductor = "0";
      this.placa = "0";

      this.peso_total = "";

      this.nombre_persona = "";
      this.nombre_emp_transp = "";
      this.nombres_conductor = "";
      this.apellidos_conductor = "";
      this.licencia_conductor = "";
      this.tipo_vehiculo = "";

      this.descrip_motivo_traslado = "";
      this.nom_ubi_partida = "";
      this.nom_ubi_llegada = "";

      this.detalles = []; // Array detalle pedidos
      this.destinatarios = [];
      this.transportistas = [];
      this.conductores = [];
      this.vehiculos = [];

      this.desabilitar = false; // form guias
      //this.desabMasivo = false; // no activar

      this.stringPdf = ""; // Limpiamos el pdf que se visualiza
      this.blobPdf = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_comprobante); // Cargamos fecha actual
    },

    hojaCargaRuta() {
      let me = this; // de carga 
      axios.get("api/Cargas/HojaCarga", {
        params: {
          ano: me.gano,
          nro_entrega: me.gnro_entrega,
          item: me.gitem,
          ruta: me.gruta,
        },
      })
        .then(function (response) {
          me.hojaCarga = response.data;
          me.pdfHojaCargaRuta();
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    pdfHojaCargaRuta() {
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;

      var columns = [
        { header: "Nº", dataKey: "nro" },
        { header: "PRODUCTO", dataKey: "descrip_producto" },
        { header: "MARCA", dataKey: "marca" },
        { header: "LOTE", dataKey: "lote" },
        { header: "CAJAS BOLSAS", dataKey: "cajbolpaq" },
        { header: "UNIDADES SUELTAS", dataKey: "undsueltas" },
        { header: "TOTAL UNIDADES", dataKey: "total_unidad" },
      ];

      var body = [];
      var foot = [];

      var totcajbolpaq = 0;
      var totundsueltas = 0;
      var totalesunidades = 0;
      var totalespesocarga = 0;

      for (var i = 0; i < this.hojaCarga.length; i++) {
        var cajbolpaq = Math.trunc(this.hojaCarga[i].total_unidad / this.hojaCarga[i].empaque);
        totcajbolpaq += cajbolpaq;
        var undsueltas = this.hojaCarga[i].empaque * ((this.hojaCarga[i].total_unidad / this.hojaCarga[i].empaque) % 1);
        totundsueltas += undsueltas;
        var total_unidad = this.hojaCarga[i].total_unidad;
        totalesunidades += total_unidad;
        totalespesocarga += this.hojaCarga[i].total_peso;

        body.push({
          nro: this.hojaCarga[i].nro,
          descrip_producto: this.hojaCarga[i].descrip_producto,
          marca: this.hojaCarga[i].marca + "\n" + this.hojaCarga[i].presentacion.toFixed(3),
          lote: this.hojaCarga[i].lote,
          //empaque: this.hojaCarga[i].empaque,
          cajbolpaq: cajbolpaq + "\n[x" + this.hojaCarga[i].empaque + "] ",
          undsueltas: undsueltas.toFixed(0),
          total_unidad: total_unidad,
        });
        if (i === this.hojaCarga.length - 1) {
          foot.push([
            {
              content: "TOTALES: ",
              colSpan: 4,
              styles: { fontStyle: "bold", halign: "right", },
            },
            {
              content: totcajbolpaq.toFixed(0),
              styles: { fontStyle: "bold", halign: "center", lineWidth: 0.2, lineColor: [227, 228, 229] },
            },
            {
              content: totundsueltas.toFixed(0),
              styles: { fontStyle: "bold", halign: "center", lineWidth: 0.2, lineColor: [227, 228, 229] },
            },
            {
              content: totalesunidades.toFixed(0),
              styles: { fontStyle: "bold", halign: "center", lineWidth: 0.2, lineColor: [227, 228, 229] },
            },
          ]);
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 47, 9.46);
      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 15, right: 70, top: 28 },
        styles: {
          overflow: "ellipsize",
          cellWidth: "wrap",
        },
        headStyles: {
          cellPadding: 0.3,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          //halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.3,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          //halign: "center",
        },
        body: [
          {
            columna0: this.usuario.direccion,
          },
          { columna0: this.usuario.distrito + " - " + this.usuario.provincia + " - " + this.usuario.departamento },
          { columna0: "Cel.: " + this.usuario.telefono },
        ],
        columns: [{ header: this.usuario.emp_comercial, dataKey: "columna0" }],
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.roundedRect(136, 17, 62, 18, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.setFontSize(12);
      doc.text(this.nomitem + " - RUTA " + this.gruta, 168, 22, "center");
      doc.text("HOJA DE CARGA", centro, 40, "center");

      doc.setFontSize(8);
      doc.text("ENTREGA " + this.gnro_entrega + " - AÑO " + this.gano, 168, 27, "center");
      doc.text(totalespesocarga.toFixed(3) + " Kg", 168, 32, "center");
      // DETALLE GUIA REMISION
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        theme: "grid",
        columns,
        body,
        foot,
        margin: { left: 15, right: 10 },

        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 7,
          halign: "center",
          valign: "middle",
          fillColor: [227, 228, 229],
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          valign: "middle",
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.5,
          fontStyle: "normal",
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          //halign: "center",
        },
        columnStyles: {
          nro: { cellWidth: 8, halign: "center", fontStyle: "bold" },
          descrip_producto: { cellWidth: "auto" },
          marca: { cellWidth: 30, halign: "center" },
          lote: { cellWidth: 40 },
          cajbolpaq: { cellWidth: 12, halign: "center" },
          undsueltas: { cellWidth: 15, halign: "center" },
          total_unidad: { cellWidth: 15, halign: "center", fontStyle: "bold" },
        },

      });

      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFont("helvetica", "bold");
          doc.text("Desarrollado por www.urpisoft.pe", centro, 285, "center");
          doc.setFont("helvetica", "italic");
          doc.text("Página " + String(i) + " de " + String(pageCount), 177, 285);
        }
      };

      addFooters(doc);

      doc.save("Hoja de Carga.pdf");
    },

    async impresionPdfMasivo() {
      let me = this;
      me.arrayBufferPdf = []; // Limpiamos para cargar pdf
      for (var i = me.guiasRemision.length - 1; i >= 0; i--) {
        me.desabMasivo = true;

        if (me.guiasRemision[i].estado == 'Aceptado') {
          await me.crearPDF(me.guiasRemision[i].idmovimiento, me.guiasRemision[i].identrega)
        }
      }
      me.desabMasivo = false;
      if (me.guiasRemision.length > 0) {
        me.downloadMergePdf(await me.mergePDFDocuments(me.arrayBufferPdf))
      }
      me.snackBar({ cl: "info", msg: "No existen guías de remisión ACEPTADAS para imprimir" });
      this.limpiar();
    },

    cargaIdGre(id) {
      this.limpiar();
      document.getElementById("container").innerHTML = ""; // Limpiamos contenedor de QR

      if (id != null) {
        const selectId = this.guiasRemision.find(
          (elemento) => elemento.idmovimiento === id
        );

        this.idmovimiento = selectId.idmovimiento;
        this.idusuario = selectId.idusuario;
        this.identrega = selectId.identrega;
        //this.idtipo_movimiento = selectId.idtipo_movimiento;
        this.idtipo_comprobante = selectId.idtipo_comprobante;
        this.idmotivo_traslado = selectId.idmotivo_traslado;
        this.serie_comprobante = selectId.serie_comprobante;
        this.num_comprobante = selectId.num_comprobante;
        this.fecha_comprobante = selectId.fecha_comprobante;
        this.fecha = selectId.fecha;
        this.link_cpe = selectId.link_cpe;
        this.motivo = selectId.motivo;
        this.observacion = selectId.observacion;

        this.nom_comprobante = selectId.nom_comprobante;

        // Entregas
        this.doc_persona = selectId.doc_persona;
        this.nombre_iiee = selectId.nombre_iiee;
        this.nivel_educativo = selectId.nivel_educativo;
        this.cod_modular = selectId.cod_modular;
        this.idubigeo_partida = selectId.idubigeo_partida;
        this.direccion_partida = selectId.direccion_partida;

        this.idubigeo_llegada = selectId.idubigeo_llegada;
        this.direccion_llegada = selectId.direccion_llegada;
        this.centro_poblado = selectId.centro_poblado;
        this.idmodalidad_transporte = selectId.idmodalidad_transporte;
        this.doc_transportista = selectId.doc_transportista;
        this.doc_conductor = selectId.doc_conductor;
        this.placa = selectId.placa;
        this.peso_total = selectId.peso_total;
        // Otros
        this.nombre_persona = selectId.nombre_persona;
        this.nombre_emp_transp = selectId.nombre_emp_transp;
        this.nombres_conductor = selectId.nombres_conductor;
        this.apellidos_conductor = selectId.apellidos_conductor;
        this.licencia_conductor = selectId.licencia_conductor;

        this.tipo_vehiculo = selectId.tipo_vehiculo;
        this.descrip_motivo_traslado = selectId.descrip_motivo_traslado;
        this.nom_ubi_partida = selectId.nom_ubi_partida;
        this.nom_ubi_llegada = selectId.nom_ubi_llegada;
      } else {
        return me.snackBar({ cl: "warning", msg: "Error al seleccionar id de gre" });
      }
    },

    /* Crear PDF guia remision */
    async crearPDF(idmov, ident) {
      // Carga contenido gre - movimiento + entrega
      await this.cargaIdGre(idmov)

      // Carga contenido de gre - detalle_entrega
      await this.listarDetalleEntregas(ident)

      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;

      // FE
      var nomcomprob = this.nom_comprobante.toUpperCase();
      // QR
      if (this.link_cpe) {
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text: this.link_cpe,
          width: 120,
          height: 120,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
      }

      var columns = [
        { header: "Nro", dataKey: "item" },
        { header: "CÓD.", dataKey: "cod_producto" },
        { header: "DESCRIPCIÓN DETALLADA", dataKey: "descripcion_detallada" },
        { header: "U/M", dataKey: "nom_unidad_medida" },
        { header: "CANT.", dataKey: "cantidad" },
      ];

      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          item: i + 1,
          cod_producto: this.detalles[i].cod_producto,
          descripcion_detallada: this.detalles[i].descripcion_detallada,
          idunidad_medida: this.detalles[i].idunidad_medida,
          cantidad: this.detalles[i].cantidad,
          nom_unidad_medida: this.detalles[i].nom_unidad_medida,
        });
      }

      // Logo
      doc.addImage(imgData, "JPEG", 15, 17, 47, 9.46);
      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 15, right: 70, top: 28 },
        styles: {
          overflow: "ellipsize",
          cellWidth: "wrap",
        },
        headStyles: {
          cellPadding: 0.3,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          //halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.3,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          //halign: "center",
        },
        body: [
          {
            columna0: this.usuario.direccion,
          },
          { columna0: this.usuario.distrito + " - " + this.usuario.provincia + " - " + this.usuario.departamento },
          { columna0: "Cel.: " + this.usuario.telefono },
        ],
        columns: [{ header: this.usuario.emp_comercial, dataKey: "columna0" }],
      });

      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 19.5, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.text("RUC " + this.usuario.ruc, 168, 22, "center");
      doc.text(nomcomprob, 168, 26, "center");
      doc.text("ELECTRÓNICA", 168, 30, "center");
      doc.text(this.serie_comprobante + "-" + this.num_comprobante.padStart(8, '0'), 168, 34, "center");

      // Configuracion 
      var margin = { left: 15, right: 15 };
      // var styles = {
      //   overflow: "ellipsize",
      //   cellWidth: "wrap",
      //   lineColor: [227, 228, 229],
      // };
      var headStyles = {
        cellPadding: 0.3,
        font: "helvetica",
        fontStyle: "normal" | "bold",
        fontSize: 6.8,
        fillColor: [227, 228, 229],
        textColor: 0,
        lineColor: [227, 228, 229],
        lineWidth: 0.2,
      };
      var columnStyles = {
        columna0: { cellWidth: 90 },
        columna1: { cellWidth: 90 },
      };
      var bodyStyles = {
        cellPadding: 0.3,
        font: "helvetica",
        fontStyle: "normal",
        fontSize: 6.8,
        textColor: 0,
        lineColor: [227, 228, 229],
        lineWidth: 0.2,
      };

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 2,
        theme: "grid",
        margin,
        // tableLineColor: 0,
        // tableLineWidth: 0.2,
        headStyles,
        bodyStyles,
        body: [
          { columna0: "DENOMINACIÓN: " + this.nombre_persona },
          { columna0: "RUC: " + this.doc_persona },
        ],
        columns: [{ header: "DESTINATARIO", dataKey: "columna0" }],
      });

      // ENVIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1.5,
        theme: "grid",
        margin,
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        headStyles,
        bodyStyles,
        columnStyles,
        columns: [
          { header: "ENVIO", dataKey: "columna0" },
          { header: "", dataKey: "columna1" },
        ],
        body: [
          {
            columna0:
              "FECHA EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"),
            columna1:
              "FECHA INICIO DE TRASLADO: " +
              moment(this.fecha_comprobante).format("DD/MM/YYYY"),
          },
          {
            columna0: "MODALIDAD DE TRANSPORTE: " + (this.idmodalidad_transporte == "01" ? "TRANSPORTE PÚBLICO" : this.idmodalidad_transporte == "02" ? "TRANSPORTE PRIVADO" : ""),
            columna1: "MOTIVO DE TRASLADO: " + this.descrip_motivo_traslado.toUpperCase(),
          },
          {
            columna0: "PESO BRUTO TOTAL (KGM): " + this.peso_total,
            columna1: "DESCRIPCIÓN: " + this.motivo,
          },
        ],
      });

      // PUNTO PARTIDA Y LLEGADA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1.5,
        theme: "grid",
        margin,
        // tableLineColor: 0,
        // tableLineWidth: 0.2,
        headStyles,
        bodyStyles,
        body: [
          {
            columna0: "P. PARTIDA: " + this.direccion_partida + " " + this.nom_ubi_partida
          },
          {
            columna0: "P. LLEGADA: " + this.direccion_llegada + " " + this.centro_poblado + " " + this.nom_ubi_llegada
          },
        ],
        columns: [
          { header: "PUNTO DE PARTIDA Y PUNTO DE LLEGADA", dataKey: "columna0" },
        ],
      });

      // TRANSPORTE PUBLICO
      if (this.idmodalidad_transporte == "01") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 1.5,
          theme: "grid",
          margin,
          //tableLineColor: 0,
          //tableLineWidth: 0.2,
          headStyles,
          bodyStyles,
          columnStyles: {
            columna0: { cellWidth: 140 },
            columna1: { cellWidth: 40 },
          },
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
          body: [
            {
              columna0: "TRANSPORTISTA: " + this.nombre_emp_transp,
              columna1: "RUC: " + this.doc_transportista,
            },
          ],
        });
      }
      // TRANSPORTE PRIVADO
      else if (this.idmodalidad_transporte == "02") {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 1.5,
          theme: "grid",
          margin,
          //tableLineColor: 0,
          //tableLineWidth: 0.2,
          headStyles,
          bodyStyles,
          columnStyles,
          columns: [
            { header: "TRANSPORTE", dataKey: "columna0" },
            { header: "", dataKey: "columna1" },
          ],
          body: [
            {
              columna0: "VEHICULO: " + this.placa + " - " + this.tipo_vehiculo,
              columna1: "LICENCIA DE CONDUCIR: " + this.licencia_conductor,
            },
            {
              columna0: "DNI: " + this.doc_conductor,
              columna1: "CONDUCTOR: " + this.nombres_conductor + " " + this.apellidos_conductor,
            },
          ],
        });
      }

      // DETALLE GUIA REMISION
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1.5,
        theme: "grid",
        columns,
        body,
        margin,
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        headStyles: {
          cellPadding: 0.3,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 6.8,
          halign: "center",
          fillColor: [227, 228, 229],
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        bodyStyles,
        columnStyles: {
          item: { cellWidth: 8, halign: "center" },
          cod_producto: { cellWidth: 16, halign: "center" },
          descripcion_detallada: { cellWidth: "auto" },
          nom_unidad_medida: { cellWidth: 16, halign: "center" },
          cantidad: { cellWidth: 10, halign: "right" },
        },
      });

      // OBSERVACIONES
      if (this.observacion.length > 0) {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 1.5,
          theme: "grid",
          margin,
          //tableLineColor: 0,
          //tableLineWidth: 0.2,
          headStyles,
          bodyStyles,
          body: [
            {
              columna0: this.observacion,
            },
          ],
          columns: [{ header: "OBSERVACIONES", dataKey: "columna0" }],
        });
      }

      let finalY = doc.lastAutoTable.finalY;
      //doc.setFont("helvetica", "normal");
      //doc.text("Consulte su comprobante en SUNAT", centro, finalY + 5, "center");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(6);
      doc.text("Representación impresa de la GUÍA DE REMISIÓN ELECTRÓNICA", 18, finalY + 6);
      this.link_cpe ? (doc.addImage(imageQR, "JPEG", 157, finalY + 4, 35, 35)) : '';

      doc.setFont("helvetica", "bold");
      doc.text("Desarrollado por www.urpisoft.pe", centro, 285, "center");
      doc.setFont("helvetica", "italic");
      doc.text("Página 1 de 1", 177, 285);

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");

      const bufferPdf = doc.output('arraybuffer');
      // Creamos array de pdfs
      this.arrayBufferPdf.push({
        buffer: bufferPdf,
      });

      if (this.link_cpe) {
        await this.subirPdf(); // Subimos pdf blob a nuetra api
      }
      /* Crear PDF guia remision */
    },

    downloadMergePdf(mergePdf) {
      var link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([mergePdf], { type: 'application/pdf' }),);
      var fileName = "Guías de remisión A" + this.gano + " E" + this.gnro_entrega + " I" + this.gitem + " R" + this.gruta;
      link.download = fileName;
      link.click();
    },

    async mergePDFDocuments(documents) {
      //console.log(document)
      const mergedPdf = await PDFDocument.create();

      for (let document of documents) {
        //console.log(document.array)
        document = await PDFDocument.load(document.buffer);

        const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      return await mergedPdf.save();
    },

    async subirPdf() {
      let me = this;

      // Código para editar usuario
      let formData = new FormData();
      formData.append("file", me.blobPdf);
      formData.append(
        "nomcomprobante",
        this.usuario.ruc +
        "-" +
        this.idtipo_comprobante +
        "-" +
        this.serie_comprobante +
        "-" +
        Number(this.num_comprobante)
      );
      await axios.post("api/Movimientos/SubirFile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          me.disableBMail = false; // se habilita boton para enviar por mail
          //console.log(response);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>