import Vue from 'vue'
import Router from 'vue-router'
import decode from 'jwt-decode'

import Inicio from './views/Inicio.vue'
import Soluciones from './views/Soluciones.vue'
import Dashboard from './views/Dashboard.vue'
import Empresa from './components/Empresa.vue'
import Rol from './components/Rol.vue'
import Usuario from './components/Usuario.vue'
import Unidad_Medida from './components/Unidad_Medida.vue'
import Entrega from './components/Entrega.vue'
import Carga from './components/Carga.vue'
import Guia_Remision from './components/Guia_Remision.vue'
import Persona from './components/Persona.vue'
import Conductor from './components/Conductor.vue'
import Transportista from './components/Transportista.vue'
import Vehiculo from './components/Vehiculo.vue'
import Login from './components/Login.vue'


Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //#region Pagina
    {
      path: '*',
      redirect: '/inicio',
    },
    {
      path: '/',
      redirect: '/inicio',
    },
    {
      path: '/inicio',
      name: 'inicio',
      component: Inicio,
    },
    {
      path: '/soluciones',
      name: 'soluciones',
      component: Soluciones,
    },
    {
      path: '*',
      redirect: '/login',
      component: Login,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    //#endregion Pagina

    //#region Dashboard
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        administradorAuth: true,
        usuarioAuth: true,
      }
    },
    //#endregion Dashboard

    {
      path: '/entrega',
      name: 'entrega',
      component: Entrega,
      meta: {
        administradorAuth: true,
        usuarioAuth: true,
      }
    },


    {
      path: '/carga',
      name: 'carga',
      component: Carga,
      meta: {
        administradorAuth: true,
        usuarioAuth: true,
      }
    },

    //#region Guia Remision
    {
      path: '/guiasremision',
      name: 'guias_remision',
      component: Guia_Remision,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    {
      path: '/persona',
      name: 'persona',
      component: Persona,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    {
      path: '/conductor',
      name: 'conductor',
      component: Conductor,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    {
      path: '/transportista',
      name: 'transportista',
      component: Transportista,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    {
      path: '/vehiculo',
      name: 'vehiculo',
      component: Vehiculo,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    //#endregion Guia Remision

    //#region Mantenimiento
    {
      path: '/empresas',
      name: 'empresas',
      component: Empresa,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Usuario,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/roles',
      name: 'roles',
      component: Rol,
      meta: {
        administradorAuth: true
      }
    },
    {
      path: '/unidadmedidas',
      name: 'unidad_medidas',
      component: Unidad_Medida,
      meta: {
        administradorAuth: true,
        usuarioAuth: true
      }
    },
    //#endregion Mantenimiento

  ]
})

router.beforeEach((to, from, next) => {

  let token = localStorage.getItem("token")
  let usuario = token == null ? "" : decode(token)

  let ruta = false;
  let expr = usuario.rol;
  switch (expr) {
    case 'Administrador':
      ruta = to.matched.some(record => record.meta.administradorAuth)
      break;
    case 'Usuario':
      ruta = to.matched.some(record => record.meta.usuarioAuth)
      break;
  }

  if (ruta && !token) {
    next('inicio')
  } else if (!ruta && token) {
    next('dashboard')
  } else {
    next();
  }

})

export default router
